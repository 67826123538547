.chart-legend {
  position: absolute;
  top: 30px;
  right: 30px;

  > div {
    width: auto!important;
  }

  .legend-title {
    display: none;
  }

  .legend-wrap.legend-wrap {
    width: auto;
  }

  .legend-labels.legend-labels {
    background: transparent;
    display: flex;
    border-radius: 0;
    float: none;
    line-height: auto;
    width: auto;
  }

  .legend-label.legend-label {
    margin: 0 0 0 8px;
    font-size: 12px;
    line-height: 1;

    ngx-charts-legend-entry {
      > span {
        display: flex;
        align-items: center;
      }
    }
  }

  .legend-label-color.legend-label-color {
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
  }

  .legend-label-text.legend-label-text {
    color: #969dad;
    width: auto;
  }
}

.ngx-charts.ngx-charts {
  width: 100%;

  //.gridline-path {
  //  &.gridline-path-horizontal {
  //    stroke: none;
  //  }
  //  &.gridline-path-vertical {
  //    stroke: #f2f2f2;
  //  }
  //}
  //
  .tick {
    text {
      fill: #939cad;
    }
  }
  //
  //.line {
  //  stroke-width: 1;
  //}
}
