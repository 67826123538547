$bodyBgColor: #f7f7f7 !default;
$textColor: #212121 !default;
$textSecondaryColor: #616161 !default;

$contentBgColor: #ffffff !default;
$contentAltBgColor: #eceff1 !default;
$overlayContentBgColor: #ffffff !default;
$dividerColor: #e4e4e4 !default;
$hoverBgColor: rgba(0, 0, 0, 0.04);
$solidSurfaceTextColor: #ffffff !default;

$menuShadow: 2px 0 4px -1px rgba(0, 0, 0, 0.2), 4px 0 5px 0 rgba(0, 0, 0, 0.14), 1px 0 10px 0 rgba(0, 0, 0, 0.12);
$submenuShadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
$menuTooltipBgColor: #1c202c !default;
$menuTooltipTextColor: #ffffff !default;

$pink: #e91e63 !default;
$indigo: #3f51b5 !default;
$yellow: #ffc107 !default;
$orange: #ff9800 !default;
$teal: #009688 !default;
$cyan: #00acc1 !default;
$bluegrey: #546e7a !default;
$purple: #9c27b0 !default;
$deeppurple: #673ab7 !default;
$blue: #2196f3 !default;
$lightblue: #03a9f4 !default;
$green: #4caf50 !default;
$lightgreen: #8bc34a !default;
$lime: #cddc39 !default;
$deeporange: #ff5722 !default;
$brown: #795548 !default;

:root {
  --divider-color: #{$dividerColor};
}

@import './_layout_common';
