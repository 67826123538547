@import 'theme/citydeals/theme-light.scss';
@import 'layout/css/layout-light.scss';

/* Osszes nem hasznalhato gomb letiltasa */
.p-button-secondary,
.p-button-success,
.p-button-info,
.p-button-warning,
.p-button-help,
.p-button-danger {
  display: none !important;
}

/**********/
/* DOKSI: */
/**********/
/* GOMBOK:*/
/**
Jelenleg 2 gomb tipust tudsz csak hasznalni:
- sima primary ehhez nem kell extra class-t irni!
- 2 fajta gomb extra css:
  - teli szinu: p-button-rounded
  - outline: p-button-outlined p-button-rounded
 */
