@media (max-width: $mobileBreakpoint) {
  .layout-wrapper {
    .menu-wrapper {
      top: $topBarHeight;
      height: calc(100% - $topBarHeight);
      transform: translate3d(-17rem, 0px, 0px);
      transition: transform $transitionDuration;
      transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    }

    &.layout-topbar-mobile-active {
      .menu-wrapper {
        top: 12rem;
        height: calc(100% - 12rem);
      }
    }

    &.layout-menu-mobile-active {
      overflow: hidden;
      height: 100vh;

      .menu-wrapper {
        transform: translate3d(0px, 0px, 0px);
      }

      .layout-mask {
        display: block;
      }
    }
  }
}
