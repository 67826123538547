.layout-topbar-blue {
  $topbarBgColor: $white;
  $topbarLeftBgColor: $white;

  $topbarMenuButtonBgColor: #fbc02d;
  $topbarMenuButtonTextColor: #212121;

  $topbarItemTextColor: $grey;
  $topbarItemTextHoverBgColor: rgba(255, 255, 255, 0.12);

  @import '../_topbar_exports';
  @import '../_topbar_theme';
}
