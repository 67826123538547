.layout-menu-light {
  $menuBgColor: #fdfeff;

  $rootMenuTextColor: #657380;

  $menuItemTextColor: #515c66;
  $menuItemHoverBgColor: rgba(0, 0, 0, 0.04);
  $activeMenuItemTextColor: var(--primary-menu-text-color);
  $activeMenuItemBgColor: var(--primary-lightest-color);

  $inlineMenuBorderColor: #e4e4e4;

  @import '../_menu_exports';
  @import '../_menu_theme';
}
