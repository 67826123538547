/* Add your customizations of the theme here */
/* PrimeNG globalis feluliras helye */
@import './../../variables';

.layout-wrapper .layout-main {
  padding-top: $topBarHeight !important;
  padding-bottom: 44px;
}

.layout-topbar {
  height: $topBarHeight !important;

  .layout-topbar-right {
    justify-content: flex-end !important;

    .layout-topbar-action-panel {
      top: $topBarHeight !important;
    }
  }

  .notifications {
    .layout-topbar-action-panel {
      img {
        width: 32px;
        height: 32px;
      }

      a {
        padding: 0.5rem 0;

        i {
          width: 42px;
          height: 42px;
          border: 1px solid transparent;
          border-radius: 50%;
          margin: 0.5rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .app {
        .layout-topbar-action-item {
          border-bottom: 1px solid var(--divider-color);

          &:last-child {
            border-bottom: 0 none;
          }
        }
      }
    }
  }
}

/* form */
ngx-form-error > div.error {
  padding: 0.25rem !important;
  color: $errorColor;
}

h2.layout-title {
  margin: 40px 0px 10px 0px;
  color: #454f63;
  font-size: 34px;
  font-weight: bold;
}

/* mobile */

@media (max-width: $mobileBreakpoint) {
  .layout-wrapper .layout-main {
    padding-bottom: 0 !important;
    max-width: 100% !important;
  }

  h2.layout-title {
    font-size: 26px !important;
    margin: 2.5rem 1rem 1rem 0rem !important;
  }
}

/* COMMON HELPERS */

/* mobile */

//@media (max-width: $mobileBreakpoint) {
//}
