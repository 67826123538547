/* Add your variable customizations of theme here */
/* overrided and extra variables */
$buttonPaddingLeftRight: 25px;
$buttonPaddingTopBottom: 12px;
$buttonPadding: $buttonPaddingTopBottom $buttonPaddingLeftRight;
$buttonHoverBg: #218437;
$roundedButtonBorderRadius: 20px;
$buttonBorderSize: 1px;

$topBarHeight: 54px;
