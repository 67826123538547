.layout-topbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 4rem;
  transition: width $transitionDuration;
  display: flex;

  ul {
    @include reset-list;
  }

  .layout-topbar-left {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    .layout-topbar-logo {
      width: 17rem;
      height: 100%;
      padding-right: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .layout-topbar-mobile-button {
      display: none;
      align-items: center;
      padding: 0 0.8rem;
      margin: 0 0.5rem 0 auto;
      border-radius: 50%;
      height: 3.2rem;
      cursor: pointer;
    }
  }

  .layout-topbar-right {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding-left: 2rem;
    height: 100%;

    .layout-topbar-actions-left {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .layout-topbar-actions-right {
      height: 100%;

      .layout-topbar-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;

        .layout-topbar-item {
          display: flex;
          align-items: center;
          position: relative;

          .layout-topbar-action {
            user-select: none;
            padding: 0 0.8rem;
            margin: 0 0.4rem;
            border-radius: 4px;
            height: 3.2rem;
            min-width: 3.2rem;
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          .layout-topbar-action-panel {
            position: absolute;
            top: 4rem;
            right: 0;
            z-index: 1000;
            min-width: 25rem;
            padding: 1rem 0;
            @include material-overlay-transform;

            .layout-topbar-action-item {
              cursor: pointer;
              padding: 1rem;
            }
          }

          &.layout-search-item {
            .layout-search-panel {
              height: 4rem;
              width: 100%;
              position: fixed;
              top: 0;
              left: 0;
              z-index: 1000;
              @include material-overlay-transform;

              input {
                border-radius: 0;
                border: 0 none;

                &:focus {
                  outline: 0 none;
                  box-shadow: none;
                }
              }

              i {
                font-weight: bold;
              }

              .p-inputgroup-addon {
                border: 0 none;
              }

              .p-inputgroup-addon:first-child,
              .p-inputgroup button:first-child,
              .p-inputgroup input:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }

              .p-inputgroup-addon:last-child,
              .p-inputgroup button:last-child,
              .p-inputgroup input:last-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}
