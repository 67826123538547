@media (min-width: $mobileBreakpoint + 1) {
  .layout-menu-slim {
    .menu-wrapper {
      width: 4.5rem;

      .layout-menu-container {
        app-menu {
          overflow-y: visible;
          flex-grow: 1;
        }

        .layout-menu {
          display: flex;
          flex-direction: column;
          padding-top: 1rem;

          > li {
            position: relative;

            > a {
              display: flex;
              justify-content: center;

              i {
                font-size: 1.5rem;
              }

              .layout-submenu-toggler,
              .layout-menuitem-text {
                display: none;
              }
            }

            &.layout-root-menuitem {
              > div {
                display: none;
              }
            }

            > ul {
              display: none;
              position: absolute;
              top: 0;
              left: 4.5rem;
              z-index: 100;
              padding: 0.5rem;
              overflow: auto;
              max-height: 32rem;
              min-width: 16.5rem;
              border-radius: 2px;
            }

            &.active-menuitem {
              > ul {
                display: block;
              }
            }
          }
        }

        .layout-inline-menu {
          height: 100%;

          .layout-inline-menu-action-panel {
            padding: 0;
          }
        }
      }
    }

    &.layout-wrapper {
      .layout-main {
        margin-left: 4.5rem;
      }
    }
  }
}
