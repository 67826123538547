/* extra colors */
$grey: #aeb2c1;
$darkGrey: #edeef0;
$white: #ffffff;
/* override colors */
$primaryColor: #00b267;
$primaryLightestColor: #e8eaf6;
$primaryMenuTextColor: $primaryColor;
$primaryTextColor: #ffffff;
$accentColor: #ff4081;
$accentTextColor: #ffffff;

/* primeng szarul van epitve, es ezt mashogyan nem tudjuk elerni :( */
@import './sass/variables/layout/layout_common';
/* egyeb importok */
@import './sass/variables/theme/_theme_light';
@import './sass/theme/_compact';
@import './sass/overrides/_theme_variables';
